export const ClientUrls = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_CLIENT_URLS':
            return action.payload;
        default:
            return state;
    }
}
export const fetchClientUrls = () => (dispatch) => {
    dispatch({ type: 'FETCH_CLIENT_URLS', payload: [] });
    fetch("ClientAppUrls/ClientAppUrls")
        .then(response => response.json())
        .then(data => {
            if (data)
                dispatch({ type: 'FETCH_CLIENT_URLS', payload: data });
        })
        .catch(err => console.log(err));
}
export const Clients = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_CLIENTS':
            return action.payload;
        default:
            return state;
    }
}
export const fetchClients = () => (dispatch) => {
    fetch("ClientAppUrls/Clients")
        .then(response => response.json())
        .then(data => {
            if (data)
                dispatch({ type: 'FETCH_CLIENTS', payload: data });
        })
        .catch(err => console.log(err));
}