import * as React from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import ClientUrls from './components/ClientUrls';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';

import './custom.css'

export default () => (
    <Layout>
        <PrivateRoute>
            <Route exact path='/' component={ClientUrls} />
        </PrivateRoute>
        <Route path='/login' component={Login} />
    </Layout>
);
