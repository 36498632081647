import React, { Fragment, useEffect } from 'react'
import * as action from '../store/ClientUrls';
import { logout } from '../store/User';
import { useDispatch, useSelector } from 'react-redux'
import { Table, Badge, Spinner, Card, CardBody, CardHeader, CardTitle, Button } from 'reactstrap';
const ClientUrls = () => {
    const ClientUrls = useSelector(state => Object.values(state.ClientUrls))
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(action.fetchClientUrls());
        //dispatch(action.fetchClients());
    }, []);
    return (<Card>
        <CardHeader className='bg-success text-white font-weight-bold'>
            <CardTitle>CLIENTS APP URL STATUS   <Button size='sm' className='float-right shadow' color='warning' onClick={() => dispatch(logout())}>Logout</Button></CardTitle>
          
        </CardHeader>
        <CardBody>
            {
                ClientUrls.length ?
                    <Table responsive hover size='sm'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>NAME</th>
                                <th>URL</th>
                                <th>STATUS</th>
                                <th>MESSAGE</th>
                                <th>DB REINDEX</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ClientUrls.map(({ Name, Url, Status, Message, DbReIndexMessage }, i) =>
                                    <tr key={i}>
                                        <td>{i + 1}</td>
                                        <td>{Name}</td>
                                        <td>{Url}</td>
                                        <td> {Status ? <Badge pill color='success'>O</Badge> : <Spinner color='danger' size='sm' type='grow'></Spinner>}</td>
                                        <td>{Message}</td>
                                        <td>{DbReIndexMessage}</td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table> : <Spinner color='success'></Spinner>
                    }
        </CardBody>
    </Card>)
}
export default ClientUrls;