import React, { useState, useEffect } from 'react';
import * as action from '../store/User';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom'
import { Card, CardBody, CardHeader, CardTitle, Form, FormGroup, Label, Input, Button, Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
const ClientUrls = () => {
    const history = useHistory();
    const location = useLocation();
    let { form } = location.state || { form: { pathname: "/" } };
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState(false);
    const [UserName, setUserName] = useState("");
    const [Password, setPassword] = useState("");
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(action.logout())
    }, [])
    const handleSubmit = async (event) => {
        setLoading(true)
        setOpen(false);
        event.preventDefault();
        const formValues = {
            UserName,
            Password
        }
        var { IsLoggedIn, Message } = await dispatch(action.login(formValues));
        setLoading(false)
        if (IsLoggedIn) {
            history.replace(form);
        } else {
            setMessage(Message);
            setOpen(true);
        }
    }
    return (
        <div className="container" >
            <div className="row align-items-center vh-100">
                <div class="col-md-6 col-sm-12 col-xs-12 mx-auto">
                    <div className="col d-flex justify-content-center">
                        <Card className="shadow border" style={{ width: "30rem" } }>
                            <CardHeader className='font-weight-bold text-center bg-primary text-white '>
                                <CardTitle>LOGIN</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>

                                    <FormGroup>
                                        <Label
                                            for="UserName"
                                            hidden
                                        >
                                            User Name
                                        </Label>
                                        <Input
                                            required
                                            id="UserName"
                                            name="UserName"
                                            placeholder="User Name"
                                            type="text"
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label
                                            for="Password"
                                            hidden
                                        >
                                            Password
                                        </Label>
                                        <Input
                                            required
                                            id="Password"
                                            name="Password"
                                            placeholder="Password"
                                            type="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </FormGroup>
                                    <Button type='submit' color='success' className='btn-block' disabled={loading}>
                                        Login
                                    </Button>
                                </Form>
                                {
                                    open ? <RenderToast open={open} setOpen={() => setOpen(!open)} message={message} /> : null
                                }
                            </CardBody>
                        </Card>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default ClientUrls;
const RenderToast = (props) =>
    <Modal isOpen={props.open}>
        <ModalHeader className='bg-danger text-white' toggle={props.setOpen}>
            Access Denied!
        </ModalHeader>
        <ModalBody>
            {props.message}
        </ModalBody>
    </Modal>
