import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import {Route } from 'react-router'
const PrivateRoute = ({ children, ...rest }) => {
    const user = useSelector(state => state.User)
        return (
            <Route
                {...rest}
                render={() => {
                    return user.IsLoggedIn === true ? (
                        children
                    ) : (
                        <Redirect to="/login" />
                    );
                }}
            />
        );
}
export default PrivateRoute;