export const ClientUrls = (state = [], action) => {
    switch (action.type) {
        case 'FETCH_CLIENT_URLS':
            return action.payload;
        default:
            return state;
    }
}
export const fetchClientUrls = (url) => (dispatch) => {
    fetch("ClientAppUrls")
        .then(response => response.json())
        .then(data => {
            if (data)
                dispatch({ type: 'FETCH_CLIENT_URLS', payload: data });
        })
        .catch(err => console.log(err));
}
const INITIAL_STATE = {
    IsLoggedIn: false,
    UserName: "",
    Message: ""
}
export const User = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'USER_LOGIN':
            return action.payload;
        case 'USER_LOGOUT':
            return action.payload;
        default:
            return state;
    }
}
export const login = (formValues) => (dispatch) => {
    return fetch(`Accounts/Login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formValues)
    })
        .then(response => response.json())
        .then(data => {
            if (data) {
                dispatch({ type: 'USER_LOGIN', payload: data });
                return data;
            }
        })
        .catch(err => console.log(err));
}
export const logout = () => (dispatch) => {
    dispatch({ type: 'USER_LOGOUT', payload: {} });
    dispatch({ type: 'FETCH_CLIENT_URLS', payload: [] });
}